/* eslint-disable
    func-names,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

$(() => {

  window.draw_pipeline_trend_chart = function() {
    $('#pipeline_trend_by_stage').highcharts({
      chart: { type: 'area' },
      exporting: { enabled: false },
      title: { text: '' },
      xAxis: {
        categories: $('#pipeline_trend_by_stage').data('categories'),
        tickmarkPlacement: 'on',
        title: { enabled: false },
      },
      yAxis: {
        title: { text: 'Thousands' },
        labels: {
          formatter() {
            return this.value;
          },
        },
        reversedStacks: false,
      },
      tooltip: {
        shared: true,
        valueSuffix: ' ',
      },
      plotOptions: {
        area: {
          stacking: 'normal',
          lineColor: '#666666',
          lineWidth: 1,
          marker: {
            lineWidth: 1,
            lineColor: '#666666',
          },
        },
      },
      series: $('#pipeline_trend_by_stage').data('values'),
    });
  };

  window.save_form = function(id) {
    if (id.length > 0) {
      $(`form#${id}`).trigger('submit');
    }
  };

  $('.collapse').on('show.bs.collapse', function(e) {
    e.stopPropagation();
    $(this)
      .closest('.card')
      .find('.collapse-arrow:first')
      .removeClass('fa-chevron-down')
      .addClass('fa-chevron-up');
  });

  $('.collapse').on('hide.bs.collapse', function(e) {
    e.stopPropagation();
    $(this)
      .closest('.card')
      .find('.collapse-arrow:first')
      .removeClass('fa-chevron-up')
      .addClass('fa-chevron-down');
  });

  // Currently used at list page of Account, Course, BehaviorAssessmentItem and Competency
  // Used to make an ajax call on changing inactive checkbox with inactive value in the params
  $('#list_menu').on('change', '#inactive_items', function () {
    const url = window.location.href;
    const inactive = $(this).prop('checked');
    $.ajax({
      method: 'get',
      dataType: 'json',
      url,
      data: {
        page: $('#current_page').val(),
        inactive,
      },
      success: function success(data) {
        const { html_content, paginate, paginate_selector, content_selector } = data;
        $(content_selector).html(html_content);
        $(paginate_selector).html(paginate);
      },
      error: function error() {
        console.log('AJAX Error');
      },
    });
  });
});
