/* eslint-disable
    camelcase,
    consistent-return,
    func-names,
    max-len,
    no-console,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(() => {
  $(document).on('change', '#select_competency, #select_proficiency_level, #inactive', function (
    evt,
  ) {
    let direction;
    let sort;
    const $competency = $('#select_competency');
    let competency_id = $competency.val();
    const is_ba_template = $('#is_ba_template').val();
    let sales_role_id = '';
    const account_id = $(this).data('account-id');
    if (competency_id === '') {
      competency_id = 'all';
      if ($('#behaviorassessment_user_group_select').length > 0) {
        sales_role_id = $('#behaviorassessment_user_group_select').val();
      }
      if ($('#knowledgeassessment_user_group_select').length > 0) {
        sales_role_id = $('#knowledgeassessment_user_group_select').val();
      }
    }

    const proficiency_level = $('#select_proficiency_level').val();
    const all_checked_items = [];

    const id = $('form').attr('id');
    let assessment_type = '';
    if (id.indexOf('new_behavior_assessment') >= 0 || id.indexOf('edit_behavior_assessment') >= 0) {
      assessment_type = 'behavior_assessment';
      sort = $competency.attr('data-sort');
      direction = $competency.attr('data-direction');
    }
    if (
      id.indexOf('new_knowledge_assessment') >= 0
      || id.indexOf('edit_knowledge_assessment') >= 0
    ) {
      assessment_type = 'knowledge_assessment';
    }

    let url = '';
    if (competency_id === 'all') {
      url = `/get_${assessment_type}_items?sales_role_id=${sales_role_id}`;
    } else {
      url = `/get_${assessment_type}_items`;
    }

    $(`input.${assessment_type}_item:checked`).each(function () {
      all_checked_items.push(this.value);
    });

    const inactive = $('#inactive').prop('checked');
    // eslint-disable-next-line radix
    if (assessment_type !== '' && ((competency_id === 'all' && sales_role_id !== '') || competency_id !== 'all')) {
      $.ajax({
        url: `/admin/competencies/${competency_id}${url}`,
        type: 'GET',
        data: {
          proficiency_level,
          checked_items: all_checked_items,
          account_id,
          inactive,
          is_ba_template,
          sort,
          direction,
        },
        dataType: 'script',
        error(jqXHR, textStatus, errorThrown) {
          console.log(`AJAX Error: ${textStatus}`);
        },
        success(data, textStatus, jqXHR) {},
      });
    } else {
      alert(I18n.t('views.js.select_competency'));
      $('#select_competency').focus();
    }
  });

  $('#inactive_bai').on('change', function () {
    const inactive = $(this).prop('checked');
    const competency_id = $(this).data('competency-id');

    $.ajax({
      url: `/admin/competencies/${competency_id}/get_ba_items_for_competency_details`,
      type: 'GET',
      data: { inactive },
      dataType: 'script',
      error(jqXHR, textStatus, errorThrown) {
        console.log(`AJAX Error: ${textStatus}`);
      },
      success(data, textStatus, jqXHR) {},
    });
  });
});
