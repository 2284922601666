/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

(() => {
  jQuery(() => {
    $('a[rel~=popover], .has-popover').popover();
    $('a[rel~=tooltip], .has-tooltip').tooltip();
  });
})();
