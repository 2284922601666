import { format } from "url";

/* eslint-disable
    camelcase,
    consistent-return,
    func-names,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/
$(() => {
  $('.user_collection_users').on('click', '.user_checkbox', function (event) {
    const $target = $(this).closest('table');
    if ($target.find('.user_checkbox:checked').length) {
      $('.remove_users_btn').removeAttr('disabled');
    } else {
      $('.remove_users_btn').attr('disabled', 'disabled');
    }
  });

  $('.remove_users_btn').on('click', function () {
    const user_ids = [];
    const user_collection_id = $(this).data('user_collection_id');
    const account_id = $(this).data('account-id');

    $('.user_checkbox:checked').each(function () {
      user_ids.push($(this).val());
    });

    if (user_ids.length && user_collection_id && account_id) {
      $.ajax({
        url: `/admin/accounts/${account_id}/user_collections/${user_collection_id}/remove_users`,
        type: 'post',
        data: {
          user_ids,
        },
      });
    }
  });

  // Change the value of assessmet_type on changing assessment_id
  // at assessment_user_collection's form while adding assessmet to
  // a user_collection from user_collection's show page.
  $("#user_collections_assessment_user_collection_form #assessment_user_collection_assessment_id").on('change', function(evt){
    const assessmentType = $(this).find(':selected').data("type");
    $("#user_collections_assessment_user_collection_form #assessment_user_collection_assessment_type").val(assessmentType);
  });
});
