/* eslint-disable
    func-names,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
$(() => {
  $('[data-toggle="tooltip"]').tooltip();

  if ($('#territory-org-tree').length) {
    $('#territory-org-tree').treegrid({
      expanderExpandedClass: 'fa fa-minus',
      expanderCollapsedClass: 'fa fa-plus',
      initialState: 'expanded',
    });
  }

  if ($('#business-org-tree').length) {
    $('#business-org-tree').treegrid({
      expanderExpandedClass: 'fa fa-minus',
      expanderCollapsedClass: 'fa fa-plus',
      initialState: 'expanded',
    });
  }
});
