/* eslint-disable
    camelcase,
    consistent-return,
    func-names,
    no-alert,
    no-console,
    no-return-assign,
    no-undef,
    no-unused-vars,
    no-use-before-define,
    no-var,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.update_learning_plan_item_order = function () {
  let recount = 0;
  $('#learning-plan-items-section-sortable tr').each(function () {
    if (
      $(this)
        .find('input:checkbox.learning_plan_item')
        .is(':checked')
    ) {
      console.log(`changing position...learning plan item${recount}`);
      $(this)
        .find('.learning_plan_item_position')
        .val(recount);
      $(this)
        .find('.learning_plan_item_destroy')
        .val('false');
      return (recount += 1);
    }
    $(this)
      .find('.learning_plan_item_destroy')
      .val('true');
    $(this)
      .find('.learning_plan_item_position')
      .val('');
  });
};

$(() => {
    // $('.active_popover').popover({
    //   html: true,
    //   placement: "top",
    //   trigger: "hover",
    //   container: "#active_chk_box",
    //   title: I18n.t('views.js.learning_plan_popover.title'),
    //   content: I18n.t('views.js.learning_plan_popover.body')
    // });

  $('#learning-plan-sortable').sortable({
    update(event, ui) {
      var count = 0;
      $('#learning-plan-sortable tr').each(function () {
        if (
          $(this)
            .find('.learning_plan_competency')
            .is(':checked')
        ) {
          $(this)
            .find('.learning_plan_competency_position')
            .val(count);
          count += 1;
        }
      });
    },
  });

  $('#learning-plan-items-section-sortable').sortable({
    update(event, ui) {
      update_learning_plan_item_order();
    },
  });

  $('#learning-plan-items-section-sortable').on(
    'change',
    'input:checkbox.learning_plan_item',
    () => {
      update_learning_plan_item_order();
      if (
        $('input:checkbox.learning_plan_item:checked').length
        === $('input:checkbox.learning_plan_item').length
      ) {
        $('#select_all_learning_plan_item').prop({ checked: true });
      } else {
        $('#select_all_learning_plan_item').prop({ checked: false });
      }
    },
  );

  $('#panel-learning-plan-items').on('change', '#select_proficiency_level', () => {
    var $lpac_level = $('#select_lpac_level');
    if ($lpac_level.val() !== '') {
      $lpac_level.val('');
    }
    filter_learning_plan_items();
  });

  $('#panel-learning-plan-items').on('change', '#select_lpac_level', () => {
    var $proficiency_level = $('#select_proficiency_level');
    if ($proficiency_level.val() !== '') {
      $proficiency_level.val('');
    }
    filter_learning_plan_items();
  });

  $('#panel-learning-plan-items').on('change', '#select_competency', () => {
    filter_learning_plan_items();
  });

  var filter_learning_plan_items = function () {
    var selected_courses = [];
    var selected_competencies = [];
    var account_id = $('#account_id').val();

    $('input.learning_plan_item:checked').each(function () {
      selected_courses.push(this.value);
    });
    console.log('selected_courses');
    console.log(selected_courses);

    $('input.learning_plan_competency:checked').each(function () {
      selected_competencies.push(this.value);
    });
    console.log('selected_competencies:');
    console.log(selected_competencies);

    var competency_id = $('#select_competency').val();

    if (selected_competencies.length !== 0 || competency_id !== '') {
      $.ajax({
        url: `/admin/accounts/${account_id}/competencies/competencies_courses`,
        type: 'GET',
        data: {
          selected_competencies,
          account_id,
          selected_courses,
          learning_plan_id: $('#learning_plan_object_id').val(),
          filter: {
            competency_id,
            proficiency_level: $('#select_proficiency_level').val(),
            lpac_level: $('#select_lpac_level').val(),
          },
        },
        dataType: 'script',
        error(jqXHR, textStatus, errorThrown) {
          console.log(`AJAX Error: ${textStatus}`);
        },
        success(data, textStatus, jqXHR) {},
      });
    } else {
      alert(I18n.t('views.js.select_competency'));
      $('#select_competency').focus();
    }
  };

  $('#select_all_learning_plan_item').on('change', function () {
    if ($(this).is(':checked')) {
      $('input.learning_plan_item').each(function () {
        $(this).prop({ checked: true });
      });
    } else {
      $('input.learning_plan_item').each(function () {
        $(this).prop({ checked: false });
      });
    }
    update_learning_plan_item_order();
  });

  $('#learning-plan-sortable').on('change', 'input:checkbox.learning_plan_competency', () => {
    filter_learning_plan_items();

    var recount = 0;
    $('#learning-plan-sortable tr').each(function () {
      if (
        $(this)
          .find('input:checkbox.learning_plan_competency')
          .is(':checked')
      ) {
        console.log(`changing position...${recount}`);
        $(this)
          .find('.learning_plan_competency_position')
          .val(recount);
        $(this)
          .find('.destroy_learning_plan_competency')
          .val('false');
        recount += 1;
      } else {
        $(this)
          .find('.destroy_learning_plan_competency')
          .val('true');
        $(this)
          .find('.learning_plan_competency_position')
          .val('');
      }
    });
  });

  $('#add_competencies').on('click', function () {
    var selected_competencies = [];
    var account_id = $(this).data('account-id');

    $('input.learning_plan_competency').each(function () {
      selected_competencies.push(this.value);
    });
    console.log('selected_competencies:');
    console.log(selected_competencies);

    $.ajax({
      url: `/admin/accounts/${account_id}/competencies/all`,
      type: 'GET',
      data: { selected_competencies },
      dataType: 'script',
      error(jqXHR, textStatus, errorThrown) {
        console.log(`AJAX Error: ${textStatus}`);
      },
      success(data, textStatus, jqXHR) {},
    });
  });

  $('#panel-learning-plan-competencies').on('click', '#myModelButton.add_competencies', () => {
    var competencies_to_add = [];
    var selected_competency_ids = [];
    var sales_role_id = $('select#learningplan_user_group_select').val();

    if ($('.competency_checkbox:checked').length > 0) {
      $('.competency_checkbox:checked').each(function () {
        competencies_to_add.push(this.value);
      });
    }

    $("input.learning_plan_competency[data-persisted='false']").each(function () {
      competencies_to_add.push(this.value);
      console.log('competencies_to_add:: ', competencies_to_add);
    });

    $('input.learning_plan_competency:checked').each(function () {
      selected_competency_ids.push(this.value);
    });

    if (sales_role_id) {
      $.ajax({
        url: `/admin/sales_roles/${sales_role_id}/get_competencies_for_learning_plan`,
        data: {
          account_id: $('#account_id').val(),
          selected_competencies: selected_competency_ids,
          competencies_to_add,
          learning_plan_id: $('#learning_plan_object_id').val(),
        },
        type: 'GET',
        dataType: 'script',
        error(jqXHR, textStatus, errorThrown) {
          console.log(`AJAX Error: ${textStatus}`);
        },
        success(data, textStatus, jqXHR) {},
      });
    }
  });

  // For Sales Role in learning_plan form
  $('select#learningplan_user_group_select').on('change', function () {
    var sales_role_id = $(this).val();
    if (sales_role_id) {
      $.ajax({
        url: `/admin/sales_roles/${sales_role_id}/get_competencies_for_learning_plan`,
        type: 'GET',
        data: {
          account_id: $('#account_id').val(),
          default_selected: true,
          learning_plan_id: $('#learning_plan_object_id').val(),
        },
        dataType: 'script',
        error(jqXHR, textStatus, errorThrown) {
          console.log(`AJAX Error: ${textStatus}`);
        },
        success(data, textStatus, jqXHR) {},
      });
    }
    $('#select_competency')
      .empty()
      .append(`<option value=''>${I18n.t('views.js.select_competency')}</option>`);
    $('tbody#learning-plan-sortable').empty();
  });

  $('#panel-learning-plan-competencies').on(
    'change',
    'input:checkbox#select_all_competencies',
    function () {
      if (this.checked) {
        $('.competency_checkbox').each(function (index) {
          $(this).prop('checked', true);
        });
      }
      $('.competency_checkbox').each(function (index) {
        $(this).prop('checked', false);
      });
    },
  );
});
