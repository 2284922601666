/* eslint-disable
    camelcase,
    consistent-return,
    func-names,
    no-console,
    no-undef,
    no-unused-vars,
    no-use-before-define,
    no-var,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(() => {
  const initAdministratorsOnLoad = function initAdministratorsOnLoad() {
    const $administratorsPage = $('#accounts_administrators_list');
    if ($administratorsPage.length > 0) {
      const $selectedUserIds = $('#selected_user_ids');
      var selected_user_ids = $('.user_select:checkbox:checked').map(function () {
        return this.value;
      }).get();
      if (selected_user_ids !== null) {
        $selectedUserIds.data('selected_user_ids', selected_user_ids.join(','));
      }
    }
  };

  $(document).on(
    'change',
    '#user_account_id, #behavior_assessment_account_id, #knowledge_assessment_account_id',
    function (evt) {
      var account_id = $(this).val();
      var user_group_type = '';

      if (account_id !== '') {
        console.log('account changed!');
        select_user_groups(account_id, user_group_type);

        $.ajax({
          url: `/admin/accounts/${account_id}/get_assessment_periods`,
          type: 'GET',
          dataType: 'json',
          error(jqXHR, textStatus, errorThrown) {
            console.log(`AJAX Error: ${textStatus}`);
          },
          success(data, textStatus, jqXHR) {
            const { options, option_selector } = data;
            $(option_selector).html(options);
          },
        });
        $.ajax({
          url: `/admin/accounts/${account_id}/get_account_territories`,
          type: 'GET',
          dataType: 'json',
          error(jqXHR, textStatus, errorThrown) {
            console.log(`AJAX Error: ${textStatus}`);
          },
          success(data, textStatus, jqXHR) {
            const { options, option_selector } = data;
            $(option_selector).html(options);
          },
        });
        $.ajax({
          url: `/admin/accounts/${account_id}/get_account_business_units`,
          type: 'GET',
          dataType: 'json',
          error(jqXHR, textStatus, errorThrown) {
            console.log(`AJAX Error: ${textStatus}`);
          },
          success(data, textStatus, jqXHR) {
            const { options, option_selector } = data;
            $(option_selector).html(options);
          },
        });

        if (evt.target.id === 'user_account_id') {
          $.ajax({
            type: 'GET',
            dataType: 'json',
            data: { user_group_type },
            url: `/admin/accounts/${account_id}/get_user_collections`,
            error(jqXHR, textStatus, errorThrown) {
              console.log(`AJAX Error: ${textStatus}`);
            },
            success(data, textStatus, jqXHR) {
              const { options, option_selector } = data;
              $(option_selector).html(options);
            },
          });
        }
      } else {
        $(
          '#behaviorassessment_user_group_select, #knowledgeassessment_user_group_select, #user_group_select',
        )
          .empty()
          .append(
            `<option value=''>${I18n.t('views.js.select_user_group')}</option>`,
          );
        $(
          '#behaviorassessment_assessment_period_select, #knowledgeassessment_assessment_period_select, #assessment_period_select',
        )
          .empty()
          .append(
            `<option value=''>${I18n.t(
              'views.js.select_assessment_period',
            )}</option>`,
          );
        $('#user_business_unit_select')
          .empty()
          .append(
            `<option value=''>${I18n.t(
              'views.js.select_business_unit',
            )}</option>`,
          );

        $('#user_territory_select')
          .empty()
          .append(
            `<option value=''>${I18n.t(
              'views.js.select_territory',
            )}</option>`,
          );

        $('#user_group_multi_select')
          .empty()
          .append(
            `<option value=''>${I18n.t(
              'views.js.select_user_groups',
            )}</option>`,
          );

        $('#user_collection_multi_select')
          .empty()
          .append(
            `<option value=''>${I18n.t(
              'views.js.select_user_collections',
            )}</option>`,
          );
      }

      $('#select_competency')
        .empty()
        .append(
          `<option value=''>${I18n.t('views.js.select_competency')}</option>`,
        );
    },
  );

  $(document).on('change', '#user_role', (evt) => {
    var account_id = $('#user_account_id').val();

    if (account_id === undefined) {
      account_id = $('#hidden_account_id').val();
    }

    var user_group_type = '';
    if (account_id && account_id.length) {
      console.log('user role changed!');
      select_user_groups(account_id, user_group_type);
    }
  });

  $(document).on('change', '#learning_plan_account_id', function (evt) {
    var account_id = $(this).val();
    if (account_id !== '') {
      console.log('account changed!');
      $.ajax({
        url: `/admin/accounts/${account_id}/get_sales_roles`,
        type: 'GET',
        dataType: 'json',
        error(jqXHR, textStatus, errorThrown) {
          console.log(`AJAX Error: ${textStatus}`);
        },
        success(data, textStatus, jqXHR) {
          const { options, option_selector } = data;
          $(option_selector).html(options);
        },
      });
    }
    $('#learning_plan_user_group_select')
      .empty()
      .append(
        `<option value=''>${I18n.t('views.js.select_user_group')}</option>`,
      );
  });

  $(document).on('change', '#accounts_administrators_list .user_select, #accounts_administrators_list #select_all', function (evt) {
    var saveBtn = $('#accounts_administrators_list .accounts_administrators_save');
    var cancelBtn = $('#accounts_administrators_list .accounts_administrators_cancel');
    if (saveBtn.is(':disabled')) {
      saveBtn.removeAttr('disabled');
      cancelBtn.parent().removeClass('custom-disable-btn');
    }
    var isDestroy = !$(this).prop('checked');
    if ($(this).hasClass('user_select')) {
      $(this).siblings('.accounts_administrator_destroy').prop('checked', isDestroy);
    } else {
      $('#accounts_administrators_list .accounts_administrator_destroy').prop('checked', isDestroy);
    }
  });


  $(document).on('click', '#accounts_administrators_save', (event) => {
    const $unselected_user_ids = $('#unselected_user_ids');
    const $selected_user_ids = $('#selected_user_ids');
    if ($('.pagination a').length > 1) {
      const url = new URL($('.pagination a:first').prop('href'));
      const unselected_user_ids = url.searchParams.get('unselected_user_ids');
      const selected_user_ids = url.searchParams.get('selected_user_ids');
      console.log(url);
      if (unselected_user_ids !== null) {
        $unselected_user_ids.val(unselected_user_ids);
      }
      if (selected_user_ids !== null) {
        $selected_user_ids.val(selected_user_ids);
      }
    } else {
      const selected = $selected_user_ids.data('selected_user_ids') || '';
      const unselected = $selected_user_ids.data('unselected_user_ids') || '';
      $unselected_user_ids.val(unselected);
      $selected_user_ids.val(selected);
    }
  });

  var select_user_groups = (account_id, user_group_type) => $.ajax({
    url: `/admin/accounts/${account_id}/get_sales_roles`,
    type: 'GET',
    data: { user_group_type },
    dataType: 'json',

    error(jqXHR, textStatus, errorThrown) {
      console.log(`AJAX Error: ${textStatus}`);
    },
    success(data, textStatus, jqXHR) {
      const { options, option_selector } = data;
      $(option_selector).html(options);
    },
  });
  var readURL = function (input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        $('.account-img-preview').attr('src', e.target.result);
        $('.img-wrap').show();
      };

      reader.readAsDataURL(input.files[0]);
    } else {
      $('#account_image').val('');
      $('.account-img-preview').attr('src', '');
      $('.img-wrap').hide();
    }
  };

  // account logo image priview and remove.
  $('#account_image').on('change', function (e) {
    readURL(this);
    var input = $(this);
    input.next('.custom-file-label').html(this.files[0].name);
    $('.account-img-preview').show();
    $('#account_remove_logo').prop('checked', false);
  });

  $('.img-wrap .close').on('click', function (e) {
    readURL(this);
    $('#account_remove_logo').prop('checked', true);
  });

  // Account features code
  $('#enable_ug_feature_control').on('change', () => $('.ug-feature-list').toggleClass('inactive-ug'));

  $('.user-org-export-btn').on('click', (e) => e.stopPropagation());

  $(document).on('change', '.btn-file :file', function () {
    var input = $(this);
    var label = input
      .val()
      .replace(/\\/g, '/')
      .replace(/.*\//, '');
    $('.file-text').val(label);
  });

  if ($('button.close-assessment-period-btn').length) {
    $('button.close-assessment-period-btn').on('click', function (event) {
      $(this)
        .parent()
        .find('form')
        .addClass('current-form');
      $('#warning-close-assessment-period-modal').modal('toggle');
    });
  }

  if ($('#warning-close-assessment-period-modal .submit-form').length) {
    $('#warning-close-assessment-period-modal .submit-form').on('click', () => {
      $('#warning-close-assessment-period-modal').modal('hide');
      $('form.close-assessment-period-form.current-form').trigger('submit');
    });
  }

  if (
    $('#warning-close-assessment-period-modal .warning-modal-close-btn')
      .length
  ) {
    $(
      '#warning-close-assessment-period-modal .warning-modal-close-btn',
    ).on('click', () => {
      $('#warning-close-assessment-period-modal').modal('hide');
      $('form.close-assessment-period-form').removeClass('current-form');
    });
  }

  $('.password-required-on-submit-btn').on('click', (event) => {
    var userPasswordInput = $('#passwordAuthModal #current_password');
    if (userPasswordInput.val().length > 0) {
      return true;
    }
    $('#passwordAuthModal').modal('show');
    return false;
  });

  $('#passwordAuthModal #submit-password-auth').on('click', (event) => {
    submitPasswordAuth(event);
  });
  $('#passwordAuthModal #current_password').on('keypress', (event) => {
    var keycode = event.keyCode ? event.keyCode : event.which;
    if (keycode === 13) {
      submitPasswordAuth(event);
    }
  });

  const toggle_show_hide_for_protected_fields = function () {
    $('.toggle-show-hide').on('click', function () {
      var toggle_button = $(this);
      var protected_field = toggle_button
        .parents('.protected-field-container')
        .find('.protected-field');

      var value = protected_field.attr('value');
      protected_field.attr('value', protected_field.text());
      protected_field.text(value);

      value = toggle_button.attr('value');
      toggle_button.attr('value', toggle_button.text());
      toggle_button.text(value);
    });
  };

  const toggle_change_confirm_for_protected_fields = function () {
    // change protected field to password type and clear the field
    var get_protected_input = function (current_elem) {
      return current_elem
        .parents('.protected-input-container')
        .find('.protected-input');
    };

    $('.change-protected-input').on('click', function () {
      var current_elem = $(this);
      var protected_input = get_protected_input(current_elem);

      protected_input
        .prop('data-value', protected_input.val())
        .prop('data-incomplete', true);

      protected_input
        .prop('type', 'password')
        .prop('disabled', false)
        .prop('readonly', false)
        .val('');

      current_elem.siblings().removeClass('d-none');
      current_elem.addClass('d-none');
    });

    $('.confirm-protected-input').on('click', function () {
      var current_elem = $(this);
      var protected_input = get_protected_input(current_elem);

      protected_input
        .prop('readonly', true)
        .prop('data-was-confirmed', true)
        .prop('data-incomplete', null);

      current_elem.siblings('.change-protected-input').removeClass('d-none');
      current_elem.siblings('.cancel-protected-input').addClass('d-none');
      current_elem.addClass('d-none');
    });

    $('.cancel-protected-input').on('click', function () {
      var current_elem = $(this);
      var protected_input = get_protected_input(current_elem);

      if (protected_input.prop('data-was-confirmed')) { protected_input.prop('readonly', true); } else {
        protected_input.prop('type', 'text').prop('disabled', true);
      }

      protected_input
        .prop('data-incomplete', null)
        .val(protected_input.prop('data-value'));

      current_elem.siblings('.change-protected-input').removeClass('d-none');
      current_elem.siblings('.confirm-protected-input').addClass('d-none');
      current_elem.addClass('d-none');
    });
  };

  const prevent_account_form_submit_if_incomplete = function () {
    $('.edit_account').on('submit', (event) => {
      // filter incomplete fields
      var incomplete_fields = $('.protected-input').filter(function () {
        return $(this).prop('data-incomplete');
      });

      if (incomplete_fields.length > 0) {
        event.preventDefault();
        alert(I18n.t('views.js.confirm_protected_fields'));
        return false;
      }
    });
  };

  const submitPasswordAuth = function (event) {
    event.preventDefault();
    var passwordField = $('#passwordAuthModal #current_password');
    var userPassword = passwordField.val();
    if (userPassword === '') {
      passwordField.addClass('is-invalid');
      return;
    }
    passwordField.removeClass('is-invalid');
    var $delete_account_form = $('#delete_account_form');
    $delete_account_form.find('#password').val(userPassword);
    $delete_account_form.trigger('submit');
    $('#passwordAuthModal').modal('hide');
  };


  toggle_show_hide_for_protected_fields();
  toggle_change_confirm_for_protected_fields();
  prevent_account_form_submit_if_incomplete();
  initAdministratorsOnLoad();
});
