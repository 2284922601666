/* eslint-disable
    consistent-return,
    func-names,
    no-alert,
    no-restricted-globals,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/

$(() => {
  const humanize = function humanize(string) {
    return string.replace(/_/g, ' ');
  };

  $('#course_status_import_modal_popup').on('hidden.bs.modal', () => {
    const $form = $('#course_status_import');
    if ($form.hasClass('import-completed')) {
      document.location = document.location.href;
    }
  });

  $('#learning-plans-tree').treegrid({
    expanderExpandedClass: 'fa fa-minus',
    expanderCollapsedClass: 'fa fa-plus',
    initialState: 'collapsed',
  });

  $('#select_all').on('click', () => {
    const checked = $('#select_all').prop('checked');
    $('.course_status_select').prop('checked', checked);
    if (checked) {
      $('.delete_selected').attr('disabled', false);
    }
    $('.delete_selected').attr('disabled', true);
  });

  $('.course_status_select').on('click', () => {
    if ($('.course_status_select:checked').length > 0) {
      $('.delete_selected').attr('disabled', false);
    } else {
      $('.delete_selected').attr('disabled', true);
    }

    if (
      $('.course_status_select:checked').length ===
      $('.course_status_select').length
    ) {
      $('#select_all').prop('checked', true);
    }
    $('#select_all').prop('checked', false);
  });

  $('.course_status_batch_form .delete_selected').on('click', (e) => {
    e.preventDefault();
    if (confirm(I18n.t('views.js.are_you_sure_to_delete_course_status'))) {
      $('.course_status_batch_form').trigger('submit');
    }
  });

  $('#course_status_import').on('submit', (event) => {
    event.preventDefault();
    const $this = $('#course_status_import');
    const $submitBtn = $this.find('#submit_import');
    const $errorPanelBody = $this.find('.import-error-panel-body');
    const $successPanelBody = $this.find('.import-success-card-body');
    const $alertDanger = $this.find('.alert-danger');
    const file = $this.find('#file')[0].files[0];
    if (typeof file === 'undefined') {
      $alertDanger.removeClass('d-none');
    }
    const formData = new FormData();
    formData.append('file', file);
    $errorPanelBody.addClass('d-none');
    $successPanelBody.addClass('d-none');
    $alertDanger.addClass('d-none');
    $submitBtn.prop('disabled', true);

    $.ajax({
      type: 'POST',
      url: $this.attr('action'),
      dataType: 'json',
      processData: false,
      contentType: false,
      data: formData,
      success: function success(data) {
        if (data.success) {
          const { result } = data;
          if (result.success) {
            let successText = '';
            for (const key in result) {
              length = result[key].length;
              if (key === 'success' || length === 0) {
                continue;
              }
              successText = `${successText}<p class="text-capitalize"><strong>${length} ${humanize(
                key,
              )}.</strong></p>`;
            }
            $successPanelBody.find('.card-body').html(successText);
            $successPanelBody.removeClass('d-none');
            $this.addClass('import-completed');
          } else {
            let errorText = '';
            for (const key in result) {
              if (key === 'success') {
                continue;
              }
              errorText = `${errorText}<p class="text-capitalize"><strong>${humanize(
                key
              )}:</strong></p>`;
              errorText = `${errorText}<p>${result[key].join('</br>')}</p>`;
            }
            $errorPanelBody.find('.panel-body').html(errorText);
            $errorPanelBody.removeClass('d-none');
            $submitBtn.prop('disabled', false);
          }
        } else {
          const errorText = data.result.error;
          $alertDanger.text(errorText);
          $alertDanger.removeClass('d-none');
          $submitBtn.prop('disabled', false);
        }
      },
      error: function error() {
        console.log('AJAX Error: #{textStatus}');
        $submitBtn.prop('disabled', false);
      },
    });
  });
});
