/* eslint-disable
    camelcase,
    consistent-return,
    func-names,
    no-alert,
    no-restricted-globals,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(() => {

  $('#application-admin-layout').on(
    'hidden.bs.modal',
    '#import_response_popup',
    function() {
      if ($(this).hasClass('imported_successfully')) {
        document.location = document.location.href;
      }
    }
  );

  $('.table-sortable').sortable();

  $('form.form-course-competency-update').on('submit', function(event) {
    event.preventDefault();
    const $this = $(this);
    const trs = $this.find('.table-sortable tr');
    let items = {};
    const params = [];
    $.map(trs, (tr, index) => {
      items.course_competency_id = $(tr).data('course-competency-id');
      items.position = index;
      params.push(items);
      items = {};
    });

    $.ajax({
      method: 'put',
      url: '/admin/competencies/course_competency_order',
      data: {
        updated_orders: params,
      },
      success(result) {
        alert(result.message);
        $this.find(':submit').prop('disabled', false);
      },
    });
  });

  $('.date_picker')
    .datepicker({
      format: $('html').data('date-format'),
      language: $('html').data('lang'),
    })
    .on('changeDate', function(ev) {
      $(this).datepicker('hide');
    });

  $('.datetime_picker').datetimepicker({
    icons: {
      time: 'fa fa-clock-o',
      date: 'fa fa-calendar',
      up: 'fa fa-arrow-up',
      down: 'fa fa-arrow-down',
      next: 'fa fa-arrow-right',
      previous: 'fa fa-arrow-left',
    },
  });

  $('.btn_send_reminder_mails').on('click', function() {
    $.post($(this).data('url'));
  });

  $('.assessment-use-custom-instructions').on('click', function() {
    if (!this.checked) {
      $('.custom-instruction').hide();
    } else {
      $('.custom-instruction').removeClass('d-none');
      $('.custom-instruction').show();
    }
  });

  $('#assessment_summaries').on('click', () => {
    const checked = $('#assessment_summaries').prop('checked');
    $('.assessment_summary').prop('checked', checked);
    if (checked) {
      $('.delete_selected').attr('disabled', false);
    }
    $('.delete_selected').attr('disabled', true);
  });

  $('.assessment_summary').on('click', () => {
    if ($('.assessment_summary:checked').length > 0) {
      $('.delete_selected').attr('disabled', false);
    } else {
      $('.delete_selected').attr('disabled', true);
    }

    if (
      $('.assessment_summary:checked').length ===
      $('.assessment_summary').length
    ) {
      $('#assessment_summaries').prop('checked', true);
    }
    $('#assessment_summaries').prop('checked', false);
  });

  $('.assessment_summaries_batch_form .delete_selected').on('click', (e) => {
    e.preventDefault();
    const checked_summaries_count = $('.assessment_summary:checked').length;
    if (checked_summaries_count > 0) {
      if (
        confirm(
          I18n.t('views.js.are_you_sure_to_delete_assessment_summaries', {
            summaries_count: checked_summaries_count,
          })
        )
      ) {
        $('.assessment_summaries_batch_form').trigger('submit');
      }
    }
  });

  $('.assessment-template-dropdown').on('change', function() {
    const assessment_type = $(this).data('assessment-type');
    const path = `/admin/${assessment_type}_assessment_templates/${$(
      this
    ).val()}/get_assessment_items`;
    $.ajax({
      method: 'get',
      url: path,
    });
  });

});
