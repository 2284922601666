$(() => {

  $('.view-template-preview').on('click', function (event) {
    event.preventDefault();
    var $this = this;
    var viewTemplateId = $this.dataset.id;
    var locale = $this.dataset.locale;
    $.ajax({
      url: "/sales_tools/admin/view_templates/".concat(viewTemplateId, "/preview"),
      type: 'GET',
      data: typeof locale !== 'undefined' ? {
        lang: locale
      } : undefined,
      error: function error(jqXHR, textStatus) {
        console.log("AJAX Error: ".concat(textStatus));
      },
      success: function success(data) {
        var $emailPreviewPopup = $('.shared-email-preview-modal');
        $emailPreviewPopup.find('.modal-email-preview-subject .mail-content').html(data.name);
        $emailPreviewPopup.find('.modal-email-preview-body .mail-content').html(data.body);
        $emailPreviewPopup.modal('show');
      }
    });
  });

});
