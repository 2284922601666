/* eslint-disable
    camelcase,
    consistent-return,
    func-names,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/
$(() => {
  $('#importParticipantsModalSubmitButton').on('click', () => {
    let $importParticipantsModal = $('#importParticipantsModal');
    const $inputField = $importParticipantsModal.find('.file-text');
    const inputFieldValue = $inputField.val();
    $importParticipantsModal = $('#importParticipantsModal');
    $importParticipantsModal
      .find('.input-group')
      .removeClass('has-error-input');
    $importParticipantsModal
      .find('.file-text')
      .removeClass('importParticipantsModal-has-error');
    $importParticipantsModal
      .find('.importParticipantsModal-help-block')
      .remove();

    if (inputFieldValue === '') {
      $importParticipantsModal
        .find('.input-group')
        .addClass('has-error-input');
      $inputField.addClass('importParticipantsModal-has-error');
      $importParticipantsModal
        .find('.modal-body')
        .append(
          `<span class='importParticipantsModal-help-block'>${I18n.t(
            'views.js.error_for_import_participants'
          )}</span>`
        );
    }
  });

  // Used for instructor view
  $('#ILT-participants').on('click', '.course_status_complete', function() {
    $.ajax({
      url: `/instructor/course_statuses/${$(this).val()}.js`,
      data: {
        ilt_session_id: $(this).data('ilt-session-id'),
      },
      type: 'patch',
    });
  });

  // load learning plans for account
  $('#ilt_session_account_id').on('change', function(evt) {
    const account_id = $(this).val();
    if (account_id !== '') {
      $.ajax({
        type: 'get',
        dataType: 'script',
        data: {
          css_id: '#ilt_session_learning_plan_id',
        },
        url: `/admin/accounts/${account_id}/get_learning_plans`,
      });
    }
  });

  $('#ilt_session_learning_plan_id').on('change', function(evt) {
    const learning_plan_id = $(this).val();
    const account_id = $(this).data('account-id');
    if (learning_plan_id !== '') {
      $.ajax({
        type: 'get',
        dataType: 'script',
        data: {
          css_id: '#ilt_session_ilt_course_id',
        },
        url: `/admin/accounts/${account_id}/learning_plans/${learning_plan_id}/get_ilt_courses`,
      });
    }
  });
});
