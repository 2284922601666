/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { get } from 'lodash';

const getAlertBox = function(alertType, alertMsg) {
  return `<div class='alert alert-${alertType} alert-dismissible fade show' role='alert'>${alertMsg}<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button></div>`;
};

$(() => {
  $(document).on('change', '#user_account_id', function(evt) {
    let bypass_help_text;
    let user_submit_warning;
    if (
      $(this)
        .find(':selected')
        .data('notification-enabled')
    ) {
      user_submit_warning = I18n.t(
        'views.js.notification_enabled.user_submit_warning'
      );
      bypass_help_text = I18n.t(
        'views.js.notification_enabled.bypass_confirmation_message'
      );
    } else {
      user_submit_warning = I18n.t(
        'views.js.notification_disabled.user_submit_warning'
      );
      bypass_help_text = I18n.t(
        'views.js.notification_disabled.bypass_confirmation_message'
      );
    }

    $('#user_bypass_confirmation').attr('title', bypass_help_text);
    $('#new-user-submit-warning-popup .modal-body p').html(user_submit_warning);

    return $.ajax({
      url: '/admin/users/update_account_users',
      type: 'GET',
      dataType: 'json',
      data: {
        account_id: $('#user_account_id option:selected').val(),
      },
      error(jqXHR, textStatus, errorThrown) {
        return console.log(`AJAX Error: ${textStatus}`);
      },
      success(data, textStatus, jqXHR) {
        const { options, option_selector } = data;
        $(option_selector).html(options);
      },
    });
  });

  const showDom = dom =>
    $(`#${dom}`)
      .prop('disabled', false)
      .parents('div.form-group')
      .removeClass('d-none');

  const hideDom = dom =>
    $(`#${dom}`)
      .prop('disabled', true)
      .parents('div.form-group')
      .addClass('d-none');

  const isSuperAdmin = () => $('#user_role').data('isSuperAdmin');

  const toggleSuperAdminFields = ({ visibility }) => {
    if (isSuperAdmin() && visibility) {
      $('.admin-access-container').removeClass('d-none');
      $('.admin-accounts-container').removeClass('d-none');
    } else {
      $('.admin-access-container').addClass('d-none');
      $('.admin-accounts-container').addClass('d-none');
    }
  };

  const toggleAccountSpecificFields = ({ visibility }) => {
    const elementIdsToToggle = [
      'user_account_id',
      'user_account_name',
      'mgr_users_select',
      'user_group_multi_select',
      'user_territory_select',
      'user_business_unit_select',
      'user_collection_multi_select',
    ];

    if (visibility) {
      elementIdsToToggle.map(elementId => showDom(elementId));
    } else {
      elementIdsToToggle.map(elementId => hideDom(elementId));
    }

    toggleSuperAdminFields({ visibility: false });
  };

  const showHideFor = function(role) {
    switch (role) {
      case 'admin':
        toggleAccountSpecificFields({ visibility: false });
        toggleSuperAdminFields({ visibility: true });
        break;
      case 'instructor':
      case 'collaboration':
        toggleAccountSpecificFields({ visibility: false });
        break;
      case 'account_admin':
        toggleAccountSpecificFields({ visibility: false });
        showDom('user_account_id');
        showDom('user_account_name');
        break;
      case 'user':
        toggleAccountSpecificFields({ visibility: true });
        break;
      default:
        console.log(`Invalid User Role: ${role}`);
    }
  };

  $(document).on('change', '#user_role', function(evt) {
    showHideFor($(this).val());
  });

  $(document).on('change', '#user_bypass_confirmation', function(evt) {
    let warningMessage = '';
    const popupBodyPSelector = $(
      '#new-user-submit-warning-popup .modal-body p'
    );

    if ($(this).prop('checked') === true) {
      warningMessage = I18n.t(
        'views.js.save_user_warning.without_confirmation'
      );
    } else {
      warningMessage = $(this).data('warning');
    }
    popupBodyPSelector.html(warningMessage);
  });

  $(document).on('blur', '#user_password', function() {
    const hasPassword = $(this).val() !== '';
    const bypassConfirmationCheckBox = $('#user_bypass_confirmation');
    bypassConfirmationCheckBox.prop('checked', hasPassword).trigger('change');
  });


  showHideFor($('#user_role').val());

  const $users_tree = $('#users-tree');
  if ($users_tree.length) {
    const set_tree_rows_color = function() {
      const $rows = $users_tree.find('.jstree-wholerow');
      return $.each($rows, (i, row) => {
        if (i % 2 === 0) {
          $(row).addClass('even');
        } else {
          $(row).removeClass('even');
        }
      });
    };

    const account_id = $users_tree.data('accountid');
    $users_tree
      .on('redraw.jstree', e => {
        console.log('redraw.jstree');
        set_tree_rows_color();
        setTimeout(() => {
          set_tree_rows_color();
        }, 20);
      })
      .on('select_node.jstree', (e, data) => {
        if (data.node.original.url) {
          window.open(data.node.original.url, '_blank');
        }
      })
      .jstree({
        core: {
          animation: 0,
          check_callback: true,
          themes: {
            stripes: true,
            responsive: true,
          },
          data: {
            url(node) {
              return `/admin/accounts/${account_id}/users/list.json`;
            },
            data(node) {
              return { id: node.id };
            },
            complete(data) {
              return console.log(data);
            },
          },
        },
        plugins: ['search', 'wholerow'],
      });

    let search_text_timeout = false;
    $('#search_text').keyup(() => {
      if (search_text_timeout) {
        clearTimeout(search_text_timeout);
      }
      search_text_timeout = setTimeout(() => {
        const v = $('#search_text').val();
        $users_tree.jstree(true).search(v);
      }, 250);
    });
  }

  $('#show_completed_plans').on('change', function() {
    const userId = $(this).data('user');
    const pastCompleted = $(this).is(':checked');
    $.ajax({
      url: `/admin/users/${userId}/learning_plans`,
      type: 'GET',
      data: { past_completed: pastCompleted },
    });
  });

  const show_modal_popup_for_button = function(trigger_button, popup_id) {
    if (!trigger_button.data('disabled')) {
      $(`#${popup_id}`).modal({
        backdrop: 'static',
        keyboard: false,
        show: true,
      });
    }
  };

  $('#login-as-user-warning-popup button.submit-form').on(
    'click',
    function() {
      const userId = $(this).data('user-id');
      return $.ajax({
        url: `/admin/users/${userId}/impersonate`,
        type: 'POST',
        dataType: 'json',
        error(jqXHR, textStatus, errorThrown) {
          return console.log(`AJAX Error: ${textStatus}`);
        },
        success(data, textStatus, jqXHR) {
          return window.location.replace('/');
        },
      });
    }
  );

  $('.shared-warning-modal').on('hidden.bs.modal', function(e) {
    $(this)
      .find('.message-box')
      .html('');
    $(this)
      .find('button.submit-form')
      .prop('disabled', false);
  });

  $('.submit-form-btn').on('click', function(e) {
    e.preventDefault();
    if ($('#user_account_id').length) {
      if (
        $('#user_account_id :selected').val() &&
        $('#user_account_id').is(':visible')
      ) {
        return show_modal_popup_for_button(
          $(this),
          'new-user-submit-warning-popup'
        );
      }
      const form_id = $(
        '#new-user-submit-warning-popup button.submit-form'
      ).data('user-form-id');
      return save_form(form_id);
    }
    return show_modal_popup_for_button(
      $(this),
      'new-user-submit-warning-popup'
    );
  });

  $('.reset-mfa-form').on('ajax:beforeSend', function() {
    $('.reset-mfa-btn span.spinner-border').removeClass('d-none');
  });

  $('.reset-mfa-form').on('ajax:complete', function(_, response) {
    if (response.status === 200)
      if (get(response, 'responseJSON.status') === 'success') {
        $('.reset-mfa-form').attr('action', '#');
        $('.reset-mfa-btn').attr('disabled', true);
        $('.reset-mfa-btn').addClass('custom-disable-btn');
        $('.container#body-container').prepend(
          getAlertBox('success', response.responseJSON.message)
        );
      } else if (get(response, 'responseJSON.status') === 'failure') {
        $('.container#body-container').prepend(
          getAlertBox('danger', response.responseJSON.message)
        );
      }

    $('.reset-mfa-btn span.spinner-border').addClass('d-none');
  });

  let previous = '';

  $('#user_role')
    .on('focus', function() {
      previous = this.value;
    })
    .on('change', function() {
      if (previous === 'collaborator') {
        $('.account_select_box_for_collaborator_role_change').removeClass(
          'd-none'
        );
      } else if (previous === 'user' && this.value === 'collaborator') {
        $('.account_select_box_for_collaborator_role_change').addClass(
          'd-none'
        );
      }

      previous = this.value;
    });

  return $('#new-user-submit-warning-popup button.submit-form').on(
    'click',
    function() {
      const form_id = $(this).data('user-form-id');
      return save_form(form_id);
    }
  );

});
