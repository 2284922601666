/* eslint-disable
    camelcase,
    consistent-return,
    func-names,
    no-plusplus,
    no-undef,
    prefer-destructuring,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(() => {
  const extractParamsFromUrl = function (url) {
    const params = {};
    const query_string = url.slice(url.indexOf('?') + 1);
    const params_pairs = query_string.split('&');

    let i = 0;
    while (i < params_pairs.length) {
      const key_and_val = params_pairs[i].split('=');
      params[key_and_val[0]] = key_and_val[1];
      i++;
    }
    return params;
  };

  $('.save_and_translation').on('click', () => {
    const url = $('form').attr('action');
    $('form').attr('action', `${url}?save_with_translation=true`);
    $('form').trigger('submit');
  });

  $('.short-behavior-assessment-items a').on('click', function () {
    const $this = $(this);
    const sales_role_id = $('#behaviorassessment_user_group_select').val();

    if (sales_role_id !== '') {
      const $competency = $('#select_competency');
      const is_ba_template = $('#is_ba_template').val();
      let competency_id = $competency.val();
      const account_id = $competency.data('account-id');
      const proficiency_level = $('#select_proficiency_level').val();
      const inactive = $('#inactive').prop('checked');

      if (competency_id === '') {
        competency_id = 'all';
      }

      let all_checked_items = '';
      $('input.behavior_assessment_item:checked').each(function () {
        all_checked_items = `${all_checked_items}&checked_items[]=${this.value}`;
      });

      let url = `/admin/competencies/${competency_id}/get_behavior_assessment_items?sales_role_id=${sales_role_id}&inactive=${inactive}`;

      const existing_url = $this.attr('href');
      const url_params = extractParamsFromUrl(existing_url);
      let sorting_order = url_params.direction;
      const sort_column = url_params.sort;

      if (!(existing_url.indexOf(':id') !== -1)) {
        sorting_order = url_params.direction;
        if (sorting_order === 'asc') {
          sorting_order = 'desc';
        } else {
          sorting_order = 'asc';
        }
      }

      $competency.attr('data-sort', sort_column);
      $competency.attr('data-direction', sorting_order);

      url = `${url}&direction=${sorting_order}&sort=${sort_column}&proficiency_level=${proficiency_level}&account_id=${account_id}&is_ba_template=${is_ba_template}${all_checked_items}`;

      $this.attr('href', url);
    }
  });
});
