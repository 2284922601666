/* eslint-disable
    func-names,
    no-undef,
    prefer-rest-params,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(() => {
  const init_i18n_dataset = function () {
    const $target = $('#i18n_locale_data');
    I18n.defaultLocale = $target.data('defaultLocale');
    I18n.locale = $target.data('locale');
    I18n.fallbacks = $target.data('fallbacks');
  };

  const init_google_analytics = function () {
    const gtag = function () {
      dataLayer.push(arguments);
    };
    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());
    gtag('config', $('#google-tag-manager-script').data('trackingId'));
  };

  init_i18n_dataset();
  init_google_analytics();
});
