/* eslint-disable
    camelcase,
    consistent-return,
    func-names,
    max-len,
    no-alert,
    no-restricted-globals,
    no-undef,
    no-unused-vars,
    no-use-before-define,
    no-var,
    radix,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/

$(() => {
  $(document).on('click', '.course_competency', function(evt) {
    $(this)
      .parents('div.checkbox')
      .siblings()
      .find('.course_competency_destroy')
      .prop('checked', !$(this).prop('checked'));
    return $(this)
      .parents('div.checkbox')
      .siblings()
      .find('.course_competency_is_active')
      .prop('checked', $(this).prop('checked'));
  });

  $(document).on('change', '.course_type', function() {
    replaceFieldsOnCourseTypeChange($(this));
    show_hide_scrome_course($(this));
    return fetch_scorm_colud_courses($(this));
  });

  // Used to move 'Inactive' CheckBox on the dom when course_type changed
  const replaceFieldsOnCourseTypeChange = function(courseTypeField) {
    if (courseTypeField.val() === 'assignment') {
      $('.inactive-checkbox-container').insertBefore('.course-type-container');
    } else {
      $('.inactive-checkbox-container').insertBefore(
        '.bottom-action-buttons-container'
      );
    }
  };

  var show_hide_scrome_course = function(dom_ele) {
    if (dom_ele.val() === 'olt') {
      $(
        '.scorm_course, .place_out_course, .inactive-checkbox-container, .use-zoomi-player, .request-zoomi-reg'
      ).show();
      $('.place_out_passing_score').hide();
    } else if (dom_ele.val() === 'place_out') {
      $(
        '.scorm_course, .place_out_passing_score, .use-zoomi-player, .request-zoomi-reg'
      ).show();
      $('.place_out_course, .inactive-checkbox-container').hide();
      $('.inactive-checkbox-container #course_inactive').prop('checked', false);
    } else {
      $(
        '.scorm_course, .place_out_course, .place_out_passing_score, .use-zoomi-player, .request-zoomi-reg'
      ).hide();
      $('.inactive-checkbox-container').show();
    }

    if (dom_ele.val() === 'assignment') {
      $(
        '.require_file_upload_or_url_group, .instructions_group, #course-file-attachment-section, #manager-file-attachment-section, .send_notification, .assignment_information_for_user'
      ).show();
    } else {
      $(
        '.require_file_upload_or_url_group, .instructions_group, #course-file-attachment-section, #manager-file-attachment-section, .send_notification, .assignment_information_for_user'
      ).hide();
    }
  };

  var fetch_scorm_colud_courses = function(dom_ele) {
    if (['olt', 'place_out'].includes(dom_ele.val())) {
      $('.scorm_course_loader').show();
      $('#course_scorm_id').empty();

      return $.ajax({
        url: '/admin/scorm_courses',
        type: 'get',
        data: {
          place_out_courses: dom_ele.val() === 'place_out',
        },
        complete() {
          return $('.scorm_course_loader').hide();
        },
      });
    }
  };

  var multi_select_files = function(select_all_id, select_class, delete_class) {
    var checked = $(`#${select_all_id}`).prop('checked');
    $(`.${select_class}`).prop('checked', checked);
    if (checked && $(`.${select_class}`).length > 0) {
      return $(`.${delete_class}`).attr('disabled', false);
    }
    return $(`.${delete_class}`).attr('disabled', true);
  };

  var select_file = function(select_all_id, select_class, delete_class) {
    if ($(`.${select_class}:checked`).length > 0) {
      $(`.${delete_class}`).attr('disabled', false);
    } else {
      $(`.${delete_class}`).attr('disabled', true);
    }

    if ($(`.${select_class}:checked`).length === $(`.${select_class}`).length) {
      return $(`#${select_all_id}`).prop('checked', true);
    }
    return $(`#${select_all_id}`).prop('checked', false);
  };

  var get_popup_files = function(check_box_class_name, file_for, account_id) {
    $('#myModelBody').html('');
    var file_ids = [];
    $(`.${check_box_class_name}`).each(function() {
      return file_ids.push($(this).val());
    });

    return $.ajax({
      url: '/admin/courses/file_list',
      type: 'get',
      data: {
        selected_file_ids: file_ids,
        file_for,
        account_id,
      },
    });
  };

  var get_files = function(select_check_box_class, file_for, account_id) {
    var $selected_files_checkbox = $('.popup_select_file:checked');
    if ($selected_files_checkbox.length > 0) {
      var selected_file_ids = [];
      $selected_files_checkbox.each(function() {
        return selected_file_ids.push($(this).val());
      });

      $(`.${select_check_box_class}`).each(function() {
        return selected_file_ids.push($(this).val());
      });

      $.ajax({
        url: '/admin/courses/file_list',
        type: 'get',
        data: {
          selected_file_ids,
          keep_selected: true,
          file_for,
          account_id,
          new_course: $('#is_new_course').val(),
        },
      });

      if ($('.popup_select_file:checked').length > 0) {
        var delete_files_button_class;
        if (file_for === 'assignment') {
          delete_files_button_class = 'delete_selected_files';
        } else {
          delete_files_button_class = 'delete_manager_selected_files';
        }

        return $(`.${delete_files_button_class}`).attr('disabled', false);
      }
    }
  };

  var delete_selected_file = function(
    select_file_class,
    delete_selected_files_class,
    file_for,
    event
  ) {
    event.preventDefault();
    if (confirm(I18n.t('views.js.are_you_sure_to_delete_files'))) {
      var file_ids = [];
      $(`.${select_file_class}:checked`).each(function() {
        return file_ids.push($(this).val());
      });

      var course_id = $(`.${delete_selected_files_class}`).data('course-id');
      $(`.${select_file_class}:checked`)
        .parent('td')
        .parent('tr')
        .remove();
      if ($(`.${select_file_class}:checked`).length > 0) {
        $(`.${delete_selected_files_class}`).attr('disabled', false);
      } else {
        $(`.${delete_selected_files_class}`).attr('disabled', true);
      }

      if (course_id !== undefined) {
        return $.ajax({
          url: `/admin/courses/${course_id}/assignment_files`,
          type: 'delete',
          data: {
            account_id: $('#is_new_course').data('account-id'),
            file_ids,
            file_for,
          },
        });
      }
    }
  };

  var hide_error_class_from_duration = () =>
    $('.duration_boxes span.help-block').css('display', 'none');


  replaceFieldsOnCourseTypeChange($('.course_type'));
  show_hide_scrome_course($('.course_type'));

  $('#course_minutes, #course_hours').on('change', () => {
    var hours = parseInt($('#course_hours').val()) || 0;
    var minutes = parseInt($('#course_minutes').val()) || 0;
    return $('#course_planned_duration').val(hours * 60 + minutes);
  });

  $('.submit-assignment-btn').on('click', (event) => {
    $('#assignment-information-form').trigger('submit');
  });

  // below code are using for select file attachment with course.

  $(document).on('click', '#select_all_files', () =>
    multi_select_files(
      'select_all_files',
      'select_file',
      'delete_selected_files'
    )
  );

  $(document).on('click', '#select_all_manager_files', () =>
    multi_select_files(
      'select_all_manager_files',
      'select_manager_file',
      'delete_manager_selected_files'
    )
  );

  $(document).on('click', '.select_file', () =>
    select_file('select_all_files', 'select_file', 'delete_selected_files')
  );

  $(document).on('click', '.select_manager_file', () =>
    select_file(
      'select_all_manager_files',
      'select_manager_file',
      'delete_manager_selected_files'
    )
  );

  $('#add_files').on('click', function() {
    return get_popup_files(
      'select_file',
      'assignment',
      $(this).data('account-id')
    );
  });

  $('#add_manager_files').on('click', function() {
    return get_popup_files(
      'select_manager_file',
      'manager',
      $(this).data('account-id')
    );
  });

  $('#myModal').on('click', '.add_files', () =>
    get_files('select_file', 'assignment', $('#add_files').data('account-id'))
  );

  $('#myModal').on('click', '.add_manager_files', () =>
    get_files(
      'select_manager_file',
      'manager',
      $('#add_manager_files').data('account-id')
    )
  );

  $(document).on('click', '#popup_all_files_select', () => {
    var checked = $('#popup_all_files_select').prop('checked');
    return $('.popup_select_file').prop('checked', checked);
  });

  $(document).on('click', '.popup_select_file', () => {
    if (
      $('.popup_select_file:checked').length ===
      $('.popup_select_file').length
    ) {
      return $('#popup_all_files_select').prop('checked', true);
    }
    return $('#popup_all_files_select').prop('checked', false);
  });

  $('#course-assignment-file').on('click', '.delete_selected_files', event =>
    delete_selected_file(
      'select_file',
      'delete_selected_files',
      'assignment',
      event
    )
  );

  $('#course-manager-file').on(
    'click',
    '.delete_manager_selected_files',
    event =>
      delete_selected_file(
        'select_manager_file',
        'delete_manager_selected_files',
        'manager',
        event
      )
  );

  $(document).on(
    'click',
    '#myModelButton.add_files, #myModelButton.add_manager_files',
    function() {
      var course_id = $('#is_new_course').data('course-id');

      var file_ids = [];
      $('#myModelBody .popup_select_file:checked').each(function() {
        return file_ids.push($(this).val());
      });

      if (course_id !== undefined && file_ids.length > 0) {
        var file_for = $(this).hasClass('add_files')
          ? 'assignment'
          : 'manager';

        $.ajax({
          url: `/admin/courses/${course_id}/save_files`,
          type: 'post',
          data: {
            file_ids,
            file_for,
            account_id: $('#is_new_course').data('account-id'),
          },
          success(data) {
            if (data.status === 'ok') {
              return alert(data.message);
            }
          },
        });
      }

      return $('#myModelBody').html('');
    }
  );

  return hide_error_class_from_duration();
});
