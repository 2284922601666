/* eslint-disable
    camelcase,
    consistent-return,
    func-names,
    no-console,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://coffeescript.org/
$(() => {
  $(document).on(
    'change',
    '#behaviorassessment_user_group_select, #knowledgeassessment_user_group_select',
    function (evt) {
      const inactive = $('#inactive').prop('checked');
      const is_ba_template = $('#is_ba_template').val();

      const usergroup_id = $(this).val();
      if (usergroup_id !== '') {
        $.ajax({
          url: `/admin/sales_roles/${usergroup_id}/get_competencies`,
          type: 'GET',
          data: { inactive, is_ba_template },
          dataType: 'script',
          error(jqXHR, textStatus, errorThrown) {
            console.log(`AJAX Error: ${textStatus}`);
          },
          success(data, textStatus, jqXHR) {},
        });
      } else {
        $('#select_competency')
          .empty()
          .append(`<option value=''>${I18n.t('views.js.select_competency')}</option>`);
      }

      const sales_role_template_id = $(this)
        .find(':selected')
        .data('parent-template');
      if (sales_role_template_id && usergroup_id) {
        $('.assessment-template-dropdown')
          .closest('.form-group')
          .removeClass('d-none');
        $.ajax({
          url: `/admin/sales_roles/${usergroup_id}/get_assessment_templates`,
          type: 'GET',
          data: {
            assessment_type: $('.assessment-template-dropdown').data('assessment-type'),
          },
          dataType: 'script',
        });
      }
      $('.assessment-template-dropdown')
        .closest('.form-group')
        .addClass('d-none');
      $('.assessment-template-dropdown option:first').prop('selected', true);
    },
  );

  $(document).on('click', '.competency_user_group', function (evt) {
    $(this)
      .parents('div.form-check')
      .siblings()
      .find('.competency_user_group_destroy')
      .prop('checked', !$(this).prop('checked'));
  });

  $('.user-group-competency-table').dataTable({
    searching: false,
    paging: false,
    info: false,
  });
});
